.team{
    position: relative;
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0px;
    padding-top: 5vh;
    /* this removes glitch */
    transform: translateY(1px);
}

.team-cards-container{
    width: fit-content;
    position:relative;
    display: flex;
    flex-direction: row;
    gap: 6vw;
    left: 0vw;
}
.single-team-container{
    height: 100vh;
    width: 100vw;
}
.team-name{
    color: white;
    font-size: 30px;
    font-weight: 100;
    letter-spacing: 5px;
    position: relative;
    padding: 0px;
    text-shadow: 5px 8px 4px rgba(0,0,0,0.58);
    display: flex;
    flex-direction: row;
    padding-top: 80px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    justify-content: center;
}


@media screen and (max-width: 481px){
    .team-cards-container{
        left: -110vw;
    } 
    .team-name{
        padding-bottom: 40px;
    }
    .single-team-container{
        height: 100vh;
        width: 100vw;
        position: relative;
        top: 0vh;
    }
}

@media screen and (min-height: 990px){
    .team{
        padding-top: 20vh;
    } 
    .team-cards-container{
        margin-top: 10vh;
    }
}




