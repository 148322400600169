.meet_our_team-heading{
    position: fixed;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    left: 50%;
    margin-left: -200px;
    z-index: 0;
    height: 95vh;
    top: 50%;
    margin-top: -45vh;
}
.h1_heading_container{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0vh;
    align-items: center;
    margin: auto;
}
.meet_our_team-heading_words{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    display: flex;
    flex-direction: row;
    letter-spacing: 28px;
    justify-self: center;
    color: #ffffff;
    font-size: 100px;
    align-self: center;
    margin-bottom: -50px;
    font-weight: 900;
}
/* .meet_our_team-meet{
    transform: translateY(-4px);
}
.meet_our_team-team{
    transform: translateY(-56px);
}
.meet_our_team-our{
    transform: translateY(-250px);
} */
.word{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateZ(40px);
}

@media screen and (max-width:480px) {
    
    .meet_our_team-heading{
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 0;
        width: 100vw;
        left: 50%;
        margin-left: -50vw;
    }
    .h1_heading_container{
        display: flex;
        flex-direction: column;
        gap: 0vh;
        align-items: center;
        margin: auto;
    }
    .meet_our_team-heading_words{
        position: relative;
        font-size: 60px;
        margin-top: 20px;
    }
    .meet_our_team-meet{
        transform: translateY(0vh);
        position: relative;
    }
    .meet_our_team-our{
        transform: translateY(0vh);
        position: relative;
    }
    .meet_our_team-team{
        transform: translateY(0vh);
        position: relative;
    }
}

@media screen and (max-width:850px) {
    .meet_our_team-heading{
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 0;
        width: 400px;
        left: 50%;
        margin-left: -200px;
    }
    .h1_heading_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }
    .meet_our_team-heading_words{
        position: relative;
        font-size: 70px;
        margin-top: 20px;
    }
    .meet_our_team-meet{
        transform: translateY(0vh);
        position: relative;
    }
    .meet_our_team-our{
        transform: translateY(0vh);
        position: relative;
    }
    .meet_our_team-team{
        transform: translateY(0vh);
        position: relative;
    }
}