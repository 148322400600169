.hey {
  text-align: center;
  overflow: hidden;
  background: rgb(19, 19, 19);
}


::-webkit-scrollbar {
  width: 3px;  /* width of vertical scrollbar */
  height: 10px; /* height of horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background-color: black; /* Tailwind bg-green-100 */
  border-radius: 0px; /* Tailwind rounded-full */
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* Tailwind bg-green-400 */
  border-radius: 0px; /* Tailwind rounded-full */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c0c0c0; /* Tailwind bg-green-500 */
}


.fixed-headings{
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 15vh;
  width: 400px;
  left: 50%;
  transform: translateX(-50%);
}


.App {
text-align: center;

}

.App-logo {
height: 40vmin;
pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
.App-logo {
animation: App-logo-spin infinite 20s linear;
}
}

.App-header {
background-color: #282c34;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
color: white;
}

.App-link {
color: #61dafb;
}

@keyframes App-logo-spin {
from {
transform: rotate(0deg);
}
to {
transform: rotate(360deg);
}
}