.hero {
    position: relative;
    height: fit-content;
    width: 100vw;
    background-color: #000000;
    z-index: 2;
    display: flex;
    flex-direction: column;
   font-family: "Inter", serif;
    overflow: hidden;
}


.faq {
    z-index: 4;
    position: absolute;
    margin-bottom: 6%;
    margin-left: 0%;
    margin-top: 5%;
    font-size: 20px;
    width: 100vw;
    height: 10vh;
    padding: 1px 40px;
    justify-content: space-around;
}

.background {
    margin-top: 70%;
    margin-left: 20%;
    position: absolute;
    z-index: 3;
    scale: 1.6;
    height: 100vh;
    /* background-color: white; */
}

.Question {
    width: 80%;
    margin: 10%;
    height: 100px;
    margin-top: 35%;
    position: relative;
    padding: 0 20px;
    
    z-index: 4;
    background-color: rgb(255, 255, 255);
    text-align: center;
    border-radius: 10px;
    text-wrap: pretty;
    text-shadow: 100px;
   font-family: "Inter", serif;
}

.Question select {
    height: 80px;
    width: 80vw;
}
select:focus-visible{
    outline: none;
    color: black;
}

.Answers {
    opacity: 1;
    z-index: 4;
    display: grid;
    align-items: center;
    text-align: center;
    font-size: 12px;
    text-align: center;
    margin: 10%;
    margin-top: 0;
    border: 12px solid rgb(0, 0, 0);
    border-radius: 20px;
    width: 80%;
    padding: 20px 0px;
    color: rgb(255, 255, 255);
    height: 30vh;
    font-weight: 600;
}

.hero_video{
    border-radius: 25px;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index:2;
    margin-bottom: 40px;
}


.hero_video video{
    height: 38vh;
    aspect-ratio: 1;
    border-radius: 40px;
    object-fit: cover;
}

.mgif {
    width: 75vw;
    height: 25vh;
}

.mtopBGif {
    width: 75vw;
    height: 25vh;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 17%,
        rgba(0, 0, 0, 0) 84%,
        rgba(0, 0, 0, 1) 100%
    );
    position: absolute;
}