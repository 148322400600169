@font-face {
    font-family: 'Protomolecule-regular';
    src: url('../Fonts/Protomolecule-Regular.ttf') format('truetype');
}
.landing_2025_main {
    min-height: 100vh;
    position: relative;
    background-color: black;
    color: white;
    display: flex;
    padding: 0px 0px;
    align-items: flex-end;
    z-index: 1;
    overflow: hidden; 
}
.white_strip {
    transform-origin: center;
  }
  
  .gradient_fill {
    transform-origin: center;
    pointer-events: none; /* Prevent any interaction issues */
  }

.line_20251,
.line_20252,
.line_20253 {
  /* Remove opacity: 0 and transform from here since we're handling it with GSAP */
  position: relative;
  z-index: 2;
}

/* Add styles for SVG paths */
.letter-segment-1,
.letter-segment-2,
.letter-segment-3 {
  stroke-linecap: round;
  stroke-linejoin: round;
  will-change: stroke-dashoffset;
}

/* Add a subtle glow effect to the letters */
.letter path {
  filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5));
}

/* Enhance visibility of text */
.text_2025 {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  opacity: 0.9;
}
.eye2025{
    width: 350px;
}

.landing_2025_stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; 
    /* animation: ease star-blink  2s infinite alternate; */
    background: url('../../Images/stars1.jpg') no-repeat center center; 
    opacity: 0.25;
    background-size: cover; 
}
.white_strip {
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dashoffset 0.5s ease;
  }
.galaxy2025{
    position: absolute;
    object-fit: contain;
    top: -15%;
    opacity: 0.7;
    left: -18%;
    width: 80%;
    height: 80%;
    z-index: 0;
}
.landing_2025_Contents{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-content: space-between;
    gap: 50px;
    padding: 0PX 20px;
    position: relative;
    z-index: 2;
}
 .landing_2025_Contents-left {
    font-family: 'Protomolecule-regular', sans-serif;
    font-size: 128px;
  line-height: 120px;
  width: 55%;
}
.line_20251{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 96px;
  
   
}


.line_20251,
.line_20252,
.line_20253 {
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.5s ease-out;
}
.text_2025{
    font-size: 12.5px;
    display: flex;
    text-align: left;
    justify-content: center;
    flex-direction: column;
    width: 28%;
    line-height: 14px;
    margin-top: 8px;
    text-align: left;
  font-family: "Inter", serif;
}
.landing_2025_Contents-left p{

    font-size: 120px;
} 
.line_20253{
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-left: -16%;
    justify-content: center;
    font-size: 96px;
}
.path-overlay {
    mix-blend-mode: screen;
    pointer-events: none;
    z-index: 10;
  }
  .landing_2025_Contents-right{
    position: relative;
  }
.line_20253 img{
    position: relative;
    left: -6%;
    top: 0%;
}
.eyeball2025{
    position: absolute !important;
    top: 28% !important;
    left: 72%  !important;
    animation: ease eyeball-move 3s infinite alternate;
}
.Eye_main{
    position: relative;
}

.path-overlay{
    transform: scale(0.8);
    z-index: 5;
}
.person2025 {
    opacity: 1;
    filter: brightness(2.4);
    transform: translateY(100px);
    animation: appear 2s ease-in-out forwards;
    animation-delay: 4s;
}

@keyframes appear {
    from {
        opacity: 0;
       
    }
    to {
        opacity: 1;
       
    }
}

@keyframes eyeball-move {
    0% {
        transform: translate(0px);
    }
    
    100% {
        transform: translate(-90px);
    }
    
}
@keyframes star-blink{
    0% {
        opacity: 0.4;
    }
    
    100% {
        opacity: 0.8;
    }
    
}
@keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
  }
.blink-letter1{
    display: inline-block;
    font-size: 96px;
  color: white;
  animation: blink 10s infinite;
}

@keyframes blink {
    0%, 100% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
  }
  .blink-letter2{
    display: inline-block;
    font-size: 120px;
  color: white;
  animation: blink 4s infinite !important;
}
.blink-letter{
    display: inline-block;
    font-size: 96px;
  color: white;
  animation: blink 4s infinite;
}
@media (max-width:1285px) {
    .landing_2025_Contents-left p {
        font-size: 96px;
    }
    .blink-letter2{
        font-size: 96px;
    }
    .text_2025{
        font-size: 11.5px;
    }
    .eyeball2025{
        width: 20px;
        left: 72%  !important;
    }
    .line_20251{
        gap: 5px;
        font-size: 72px;
    }
    .blink-letter{
        font-size: 72px;
    }
    .line_20253{
        font-size: 72px;
    }
    .blink-letter1{
        font-size: 72px;
    }
    .landing_2025_Contents-left{
        line-height: 108px;
    }
    @keyframes eyeball-move {
        0% {
            transform: translate(0px);
        }
        
        100% {
            transform: translate(-70px);
        }
        
    }
}
@media (max-width:1080px){
    .landing_2025_Contents-left p {
        font-size: 72px;
    }
    
    .blink-letter2{
        font-size: 72px;
    }
    .text_2025{
        font-size: 8.5px;
    }
    .line_20251{
        gap: 5px;
        font-size: 56px;
    }
    .blink-letter{
        font-size: 56px;
    }
    .eyeball2025{
        width: 15px;
    }
    .text_2025_1{
        display: none;
    }
    @keyframes eyeball-move {
        0% {
            transform: translate(0px);
        }
        
        100% {
            transform: translate(-50px);
        }
        
    }
    .line_20253{
        font-size: 56px;
    }
    .blink-letter1{
        font-size: 56px;
    }
    .landing_2025_Contents-left{
        line-height: 98px;
    }
}
@media (max-width:968px) {
    .landing_2025_Contents-left{
        width: 100%;
    }
    .eyeball2025 {
     width: 26px !important;
    }
    .landing_2025_main{
        align-items: center;
    }
    .landing_2025_Contents{
        flex-direction: column;
    }
    @keyframes eyeball-move {
        0% {
            transform: translate(0px);
        }
        
        100% {
            transform: translate(-70px);
        }
        
    }
    .landing_2025_Contents-right{
        height: 400px;
    }
    .landing_2025_Contents-left p {
        font-size: 96px;
    }
    .blink-letter2{
        font-size: 96px;
    }
    .text_2025{
        font-size: 11.5px;
    }
    .eyeball2025{
        width: 20px;
        left: 72%  !important;
    }
    .line_20251{
        gap: 5px;
        font-size: 72px;
    }
    .blink-letter{
        font-size: 72px;
    }
    .line_20253{
        font-size: 72px;
    }
    .blink-letter1{
        font-size: 72px;
    }
    .landing_2025_Contents-left{
        line-height: 108px;
    }
    .text_2025_1{
        display: block;
    }
    .line_20253 {
        margin-left: -16%;
    }
    @keyframes eyeball-move {
        0% {
            transform: translate(0px);
        }
        
        100% {
            transform: translate(-90px);
        }
        
    }
    .person2025 {
        transform: translateY(-30px);
    }
}
@media (max-width:500px){
    .galaxy2025{
        position: absolute;
        object-fit: contain;
        top: -27%;
        opacity: 0.7;
        left: -18%;
        width: 80%;
        height: 80%;
        z-index: 0;
        
    }
    .landing_2025_main{
        min-height: 100vh;
        
    }

    .landing_2025_Contents {
        gap: 0px;
        margin-top: 50px;
        padding: 0PX;
        width: 100%;
    }
    .landing_2025_Contents-left{
        line-height: 70px;
    }

        .landing_2025_Contents-left p {
            font-size: 56px;
        }
        
        .blink-letter2{
            font-size: 56px;
        }
        .text_2025{
            font-size: 8.5px;
        }
        .line_20251{
            gap: 5px;
            font-size: 48px;
        }
        .blink-letter{
            font-size: 48px;
        }
        .eyeball2025{
            top: 29% !important;
            width: 15px !important;
        }
        .text_2025_1{
            display: none;
        }
        @keyframes eyeball-move {
            0% {
                transform: translate(0px);
            }
            
            100% {
                transform: translate(-65px);
            }
            
        }
        .line_20253{
            font-size: 48px;
        }
        .blink-letter1{
            font-size: 48px;
        }
       
}
@media (max-width:400px) {
  
    .eyeball2025{
        top: 28% !important;
        width: 15px !important;
    }
    @keyframes eyeball-move {
        0% {
            transform: translate(0px);
        }
        
        100% {
            transform: translate(-48px);
        }
        
    }
}

.landing_2025_main {
    position: relative;
    overflow: hidden;
   
  }
  .char_2025_land:nth-child(2){
    animation: blink 4s infinite !important;
  }
  .char_2025_land:nth-child(9){
    animation: blink 4s infinite !important;
  }
  
  .char_2025_land {
    display: inline-block;
    position: relative;
    z-index: 1;
  }
  
  .zoom-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 100;
  }
  .landing_2025_main {
    position: relative;
    overflow: hidden;
    perspective: 1000px;
  }
  .line_20252 {
    transform-origin: center center;
    white-space: nowrap;
  }
  .pin-spacer {
    overflow: visible; /* Ensure pin spacer doesn’t block scrolling */
  }
  
  .char {
    display: inline-block;
  }
  .try{
    height: 100vh !important;
    background-color: rgb(0, 0, 0) !important;
    color: white !important;
    position: relative!important;
    z-index: 5!important;
    display: flex !important;
    align-items: center!important;
    justify-content: center!important;
  }

  