@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;700&display=swap');


.typing_text_para{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 11px;
    width: 400px;
    color: rgb(202, 202, 202);
    z-index: 10;
}
@media (max-width: 1080px){
    .typing_text_para{
        width: 60dvw;
        font-size: 10px;
    }
}

@media (max-width: 768px){
    .typing_text_para{
        width: 80dvw;
        font-size: 8px;
    }
}

@media screen and (max-width: 480px){
    .typing_text_para{
        width: 90dvw;
        font-size: 6px;
    }
}