

@tailwind base;
@tailwind components;
@tailwind utilities;


.pin-spacer {
  overflow: hidden !important;
  }
.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.videosection .rfm-marquee-container{
  position: absolute !important;
  rotate: 12deg !important;
}





 
