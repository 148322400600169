@font-face {
    font-family: 'Protomolecule-regular';
    src: url('../../2025-Components/Fonts/Protomolecule-Regular.ttf') format('truetype');
  }

  
.rfm-marquee-container{
    position: relative !important;
    rotate: 0deg !important;
  }
  

.sponsor-wrapper-Mobile{
    font-family: 'Protomolecule-regular', sans-serif;
    background: #000000;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 0vh;
    height: auto;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    justify-content: center;
    z-index: 22;
    transform: translateY(1px);
}

.sponsor-heading-Mobile-container{
    margin-top: 500px;
    position: absolute;
    display: flex;
    width: 340px;
    height: 95vh;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    left: 50%;
    row-gap: 1vh;
    margin: 40px auto;
    margin-top: 40vh;
    margin-left: -170px;
}

.sponsor-heading-Mobile{
    font-size: 66px;
    color: #ffffff;
    font-family: "Inter", serif;
    font-weight: 900;
    position: relative;
    height: fit-content;
    padding: 0%;
    width: auto;
    margin: -20px 0px 0px 0px;
  
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    display: flex;
    flex-direction: row;
}

.sponsors-container-Mobile{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 5vw;
    height: max-content ;
    left: 0vw;
    margin-top: 10vh;
    padding: 40px;
    overflow-x: hidden;
}
.sponsors-container-Mobile2{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 5vw;
    margin-top: 10vh;
    margin-left: 0;
    height: 40vh;
    right: 0vw;
    padding: 30px;
    overflow-x: visible;
    z-index: 22;
}



.and{
    color: #00000000;
    -webkit-text-stroke: 2px #ffffff;
}