body {
    font-family: sans-serif;
}
h1, body {
    margin: 0;
}
button:focus {
    outline: 0;
}
.o-mslider {
    --scroll-perc: 0;
    --scroll-vel: 0;
    --scroll-y: 0;
    --title-size: calc(1/5 * 100vh);
    background-color: #000000;
    
    color: white;
    position: relative;
    overflow: hidden;
    z-index: 0;
    min-height: max-content;
}
.o-mslider .landing_2025_stars{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../Images/stars1.jpg') no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
    opacity: 0.20; /* Opacity for the background image */
    z-index: -1; /* Place it behind the content */
  }

.mbadal{
    display: none !important;
}

.o-mslider__title {
    z-index: 50;
    position: fixed;
    top: 8vh;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    mix-blend-mode: exclusion;
}
.o-mslider__title span {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: var(--title-size);
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    will-change: transform;
    transform-origin: 50% 100%;
}
.o-mslider__list {
    list-style: none;
    margin: 0%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
 
    width: 100vw;
    padding-top: calc(10vh - var(--title-size));
    padding-bottom: var(--title-size);
    overflow: hidden;
}
.o-mslider__item {
    --size: calc(9.2/10 * 100vw);
    width: var(--size);
    height: 85vh;
    margin-top: 55%;
    
    z-index: 5;
    padding-top: calc(var(--size) * 2/3);
    
    overflow: hidden;
      transform:  skewY(calc(var(--scroll-y) * var(--scroll-vel) * 2deg));
}

.o-mslider__item:first-child {
    margin-top: 0%;
}
.o-mslider__img {
    position: absolute;
    z-index: 1;
    opacity: 1;
    border: 2px solid white;
    background: rgba(0, 0, 0, 0.268);
    
    top:2vw;
    left: 26vw;
    border-radius: 10%;
    display: block;
    width: 300px;
    
    object-fit: cover;
    object-position: 50% 50%;
    transform: scale(1.1);
    transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.image_bg_25{
    position: absolute;
    z-index: 1;
    opacity: 1;
   
    background: linear-gradient(180deg, #0900FF 0%, #F200FF 100%);
    
    top: 3vw;
    left: 24vw;
    border-radius: 10%;
    display: block;
    width: 300px;
    height: 320px;
    object-fit: cover;
    object-position: 50% 50%;
    transform: scale(1.1);
    transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.mspeaker-desc{
    position: absolute;
    top: 63vh;
    left: -95vw;
    width: 70vw;

  z-index: 3;
font-size: 20px;
font-weight: 300;
text-align: center;
}
.mspeaker-name p{
    color: black;
}
.mSpeaker-name{
    position: absolute;
    top: 15vh;
   right: -65vw;
    z-index: 2;
    width: 40vw;
    display: inline-block;
    line-height: 55px;
    text-align: right;
    display: flex;
   flex-direction: row;
    font-size: 45px;
    font-weight: bolder;
}
.icon{
    width: 25px;
    color: white;
}
 .lin .ic{
    width: 5vw;
    color: white;
}
.lin a img{
    color: white;
}
.lin{
    display: flex;
    position: absolute;
    flex-direction: row;
    top: 114%;
    left: 19%;
    gap: 3px;
    color: white;
    justify-content: right;
}
.mprogress-bar {
    position: relative;
    height: 400px; /* Adjust height as needed */
    z-index: 1;
  }
  
  .mprogress {
   position: fixed;
    top: 70%;
    left: -10px;
    height: 8vh;
    z-index: 1;
    background-color: none;
    border-top-right-radius: 999rem;
    border-bottom-right-radius: 999rem;
    background: linear-gradient(180deg, #0900FF 0%, #F200FF 100%);
    transition: 1s forwards;
  }
  .myear{
   position: absolute;
   width: 150px;
   padding: 10px 20px;
   border-radius: 999rem;
   color: black;
   font-size: 21px;
  right: 0;
   transform: translateY(270%);
}
.option:hover {
    background-color: yellow;
  }
.maryan-style .mSpeaker-name{
    right: -60vw;
    font-size: 45px;
    line-height: 34px;
}


.mspeaker-desg{
    position: absolute;
    width: 70vw;
    opacity: 0.8;
    text-align: center;
    font-style: italic;
    z-index: 3;
    top: 54vh;
    left: 12vw;
    
   font-size: 24px;
   font-weight: bold;
}
.mabhi .lin{
    left: 50%;
}
.mbhaskar-style .lin{
    left: 45%;
}
.maryan-style .lin{
    left: 58%;
}
.mvineet-style .lin{
    left: 41%;
}
.mabhijit-style .lin{
    left: 27%;
}
.makshay-style .lin{
    left: 27%;
}
.mAbhishek-style .lin{
    left: 41%;
 }

 .mragini .lin{
    left: 50%;
    }

 
.Abhishek2 .o-mslider__img{
padding-top: 3vh;
}
.Abhishek2 .lin{
    left: 42%;
}
.Ashu .o-mslider__img{
    padding-top: 3vh;
}
.Ashu .lin{
    left: 15%;
}
.Ananad .o-mslider__img{
    padding-top: 3vh;
}
.Ananad .lin{
    left: 28%;
}
.Saanand .lin{
    left: 45%;
}
.sri .lin{
    left: 63%;
}
.Rahul .o-mslider__img{
padding-top: 3vh;
}
.Rahul .lin{
    left: 42%;
}
.mHiten .lin{
    left: 12%;
}
.mShreya .lin{
    left: 45%;
}
.mPrateek .lin{
    left: 51%;
}
.mKayden .lin{
    left: 36%;
}
.mRajesh .lin{
    left: 45%;
}
.mRenuka .lin{
    left:70%
}
.mRenuka .mSpeaker-name{
    top: 12vh;
    width: 50vw;
    right: -75%;
    font-size: 24px;
  }
 @media(max-width:550px){
  .o-mslider{
    --title-size: calc(1/8 * 100vh);
  }
  
  
  
  .o-mslider__img{
    width: 50vw;
    height: 30vh;
    left: 21vw;
    top: 4vw;
   
  }
  .image_bg_25{
    width: 50vw;
    height: 30vh;
    left: 19vw;
    top: 6vw;
  }
  .mSpeaker-name{
    right: -57vw;
    top: 8vh;
    font-size: 30px;
    line-height: 40px;
  }
  .mspeaker-desg{
    top: 42vh;
    font-size: 17px;
  }
  .mspeaker-desc{
    top: 54vh;
    font-size: 17px;
  }
.maryan-style .mSpeaker-name{
    right: -54vw;
    top: 8vh;
    font-size: 26px;
    line-height: 40px;
}
.maryan-style .lin{
    left: 51%;
}
.mHiten .mSpeaker-name{
    right: -65vw !important;
}
.mPrateek .mSpeaker-name{
    font-size: 24px;
    line-height: 30px;
}
.mPrateek .mSpeaker-name{
    right: -60vw;
}
.mPrateek .lin{
    left: 42%;
}

.mreveal .mSpeaker-name{
    font-size: 21px;
}
.Ashu .mSpeaker-name{
    right: -67vw;
}
.Ashu .lin{
    left: 6%;
}
.sri .mSpeaker-name{
    font-size: 26px;
    line-height: 30px;
}
.sri .lin{
    left: 58%;
}
.Rahul .mSpeaker-name{
    right: -62vw;
}
.Rahul .lin{
    left: 46%;
}

.Saanand .lin{
    left: 48%;
}




 }

