h1,
body {
  margin: 0;
}
button:focus {
  outline: 0;
}
.badal1{
   top: 80% !important;
   left: 0% !important;
   z-index: 1 !important;
}
.badal2{
    position: fixed;
    top:5%;
    right: 0%;
}
.o-slider {
  --scroll-perc: -70;
  --scroll-vel: 0;
  --scroll-y: 0;
 padding: 0 20px;
  font-family: "Inter", sans-serif;
 
    background-size: cover; 
  --title-size: calc(1 / 4 * 100vh);
  background-color: #000000 !important;
  position: relative;
  overflow: hidden;
z-index: 0;

}
.o-slider .landing_2025_stars{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../Images/stars1.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.20; /* Opacity for the background image */
  z-index: -1; /* Place it behind the content */
}

.o-slider.has-images .o-slider__btn span:nth-child(1) {
  display: none;
}
.o-slider.has-images .o-slider__btn span:nth-child(2) {
  display: block;
}
.o-slider.has-images .o-slider__title {
  mix-blend-mode: overlay;
}
.o-slider.has-images .o-slider__item:after {
  transform: scale(0, 1);
  transform-origin: 0 50%;
}
.o-slider.has-images .o-slider__img {
  transform: scale(1);
}
.badal1, .o-slider__title {
    will-change: transform, opacity;
    backface-visibility: hidden;
  }
.o-slider__btn {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  padding: 0.5em;
  font-size: 0.75em;
  background-color: #fff;
  border: 0;
}
.o-slider__btn span:nth-child(2) {
  display: none;
}
.o-slider__title {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  pointer-events: none;
  overflow: hidden;
  mix-blend-mode: exclusion;
}
.o-slider__title span {
  position: absolute;
  bottom: -0%;
  left: 0%;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: var(--title-size);
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  will-change: transform;
  transform-origin: 50% 100%;
}
.o-slider__list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding-top: calc(100vh - var(--title-size));
  padding-bottom: var(--title-size);
  overflow-y: hidden;
}
.o-slider__item {
  --size: calc(2 / 3 * 100vw);
  width: var(--size);
  height: 0;
  margin-top: 10%;
  border-radius: 5%;
  backdrop-filter: blur(5px); /* Apply background blur */
  -webkit-backdrop-filter: blur(5px);
  padding-top: calc(var(--size) * 2 / 3);
  border: 0.5px solid #fff;
  
  background-color: rgba(0, 0, 0, 0.432);
  z-index: 20;
  overflow: hidden;
  transform: translate(calc((170 - var(--scroll-perc)) * 0.25%), 0)
    skewY(calc(var(--scroll-y) * var(--scroll-vel) * 2deg));
}

.o-slider__item:nth-child() {
  margin-top: 0;
}

.o-slider__img {
  position: absolute;
  top: 42%;
  left: 17%;
  border-radius: 15%;
  width: 300px;
 border: 2px solid white; 
  

  filter: brightness(100%);
  display: block;
  background: rgba(255, 255, 255, 0.145);
  object-fit: cover;
  z-index: 5;
  object-position: 50% 50%;
  transform: scale(1);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.slider__desc {
  position: absolute;
  display: block;
  width: 55vw;
  height: fit-content;
  text-align: left;
  top: 69%;
  left: -124%;
  z-index: 9;
  color: rgb(251, 251, 251);

  font-size: 20px;
}
.speaker-name {
  position: absolute;

  z-index: 15;
  top: 9%;
  width: 25vw;
  text-align: right;
  line-height: 80px;

  left: 130%;

  color: #ffffff;
  font-size: 72px;
  font-weight: 700;
}

.speaker-name p {
  color: #fff;
  font-size: 92px;
}

.logos {
  position: relative;
  display: flex;

  gap: 10px;
  transform: translate(30vh, -50%);
}

.left .speaker-name {
  position: absolute;
  z-index: 5;
  top: 15%;
  left: 5%;

  color: #ffffff;
  font-size: 54px;
  font-weight: 700;
  color: #000000;
}
.left .slider__desc {
  left: 55%;
  text-align: left;
}


.progress-bar {
    position: fixed;
 
    height: 10% !important;
    top: 60%;
    left: -10px;
    width: 100%;
    height: 4px; /* Thinner bar */
    background-color: rgba(255, 255, 255, 0);
    z-index: 1;
  }
  
  .progress {
    width: 0%;
    height: 200px;
    border-top-right-radius: 999rem;
    border-bottom-right-radius: 999rem;
    height: 100%;
    background: linear-gradient(180deg, #0900FF 0%, #F200FF 100%);
    transition: width 0.1s ease-out;
  }


.year-drop {
  position: fixed;
  background-color: #eae6e6;
  font-size: 24px;
  padding: 10px 20px;
  align-items: center;
  border: 1px solid black;
  width: fit-content;
  z-index: 19;
  top: 20%;
  left: 87%;
  border-radius: 999rem;
}
.option:hover {
  background-color: yellow;
}

.logo-try {
  display: flex;
  width: 500px;
  justify-content: right;
  gap: 10px;
  text-align: right;
  transform: translateY(-90%);
}
.surname {
  transform: translateY(-40%);
}
.badal1 {
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
}

.log {
  display: flex;
  transform: translateY(15%);
  justify-content: right;
}
.log a img{
    width: 35px;
}
.speaker_desg {
  position: absolute;

  z-index: 15;
  top: 58%;
  width: 43vw;
  height: auto;
  text-align: left;
  line-height: 30px;

  left: -83.5%;

  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
}
.Aryan-style .speaker-name {
  font-size: 48px;
  line-height: 70px;

  transform: translateY(0%);
}

/*images style pc:- 2023 */

.Prateek .speaker-name{
  font-size: 65px;
}




/*speackers style pc:- 2024 */
.sripc .speaker-name {
  font-size: 65px;
}


.o-slider__title-2024 span {
  left: -15%;
}

@media (max-width: 1280px) {
  .o-slider {
    --title-size: calc(1 / 5 * 100vh);
  }
  .speaker-name {
    font-size: 45px;
    line-height: 45px;
  }
  .o-slider__img{
    top: 50%;
  }
  .speaker-name p {
    font-size: 25px;
  }
  .speaker_desg {
    top: 66%;
   
  }
  
  .slider__desc {
    top: 76%;
    font-size: 14px;
  }
  .log a img{
    width: 25px;
}
.sripc .speaker-name {
    font-size: 45px;
    line-height: 45px;
}
.Renuka .speaker-name{
  font-size: 40px;
}
.Prateek .speaker-name{
  font-size: 45px;
  line-height: 45px;
}

  
 
}
@media (max-width: 1080px) {
    .o-slider__img{
        top: 54%;
      }
      .speaker_desg {
        top: 70%;
        font-size: 16px;
      }
      
      .slider__desc {
        top: 82%;
        font-size: 14px;
      }
}
@media (max-width: 1000px){
    .o-slider__img{
        top: 56%;
      }
      .speaker_desg {
        top: 72%;
        font-size: 14px;
      }
      
      .slider__desc {
        top: 84%;
        font-size: 12px;
      }
      .sripc .speaker-name {
        font-size: 36px;
        line-height: 36px;
    }
    .Prateek .speaker-name{
      font-size: 36px;
        line-height: 36px;
    }
}