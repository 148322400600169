.sponsor-wrapper{
    background: #000;
    position: relative;
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.rfm-marquee-container{
    position: relative !important;
    rotate: 0deg !important;
  }
  

.sponsor-heading{
    font-size: 200px;
    color: #eb0028;
    font-family: "Inter", serif;
    font-weight: 900;
    position: fixed;
    height: fit-content;
    padding: 0%;
    width: auto;
    left: 0%;
    margin: 0%;
    top: -50px;
    /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); */
    display: flex;
    flex-direction: row;
}

.sponsor-wrapper{
    background: #000;
    position: relative;
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    transform: translateY(1px);
    
}

.sponsor-heading{
    font-size: 200px;
    color: #eb0028;
    font-family: "Inter", serif;
    font-weight: 900;
    position: fixed;
    height: fit-content;
    padding: 0%;
    width: auto;
    left: 0%;
    margin: 0%;
    top: -50px;
    /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); */
    display: flex;
    flex-direction: row;
}

.sponsors-container{
    position: relative;
    display: flex;
    height: fit-content;
    flex-direction: row;
    gap: 5vw;
    top: 14vh;
    overflow-x: visible;
    margin-left: 5vw;
}

@media screen and (max-width: 980px){
    .sponsor-heading{
        font-size: 20vw;
    }
}