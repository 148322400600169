.loaderbox {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 300px;
  transform: translate(-50%, -50%);
  z-index:  100000 !important;
  
}
  .loader {
    width: 8em;
    height: 8em;
  }
  
  
  
  
  #loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
  }
  .dark-mode #loader-wrapper .loader-section {
    background: linear-gradient(180deg, #333 8.1%, #121212 100%);
  }
  #loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: linear-gradient(180deg, #000000 8.1%, #080808 100%);
    z-index: 10000 !important;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .dark-mode #loader-wrapper .loader-section {
    background: linear-gradient(180deg, #000000 8.1%, #080808 100%);
  }
  
  #loader-wrapper .loader-section.section-left {
    left: 0;
  }
  
  #loader-wrapper .loader-section.section-right {
    right: 0;
  }
  
  .loaded #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;
  }
  
  .loaded #loader-wrapper .loader-section.section-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .loaded #loader-wrapper .loader-section.section-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .loaded .loaderbox {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .loader-logo {
   
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 2s ease-in;
    animation: scaleIn 1.5s ease-out forwards,
             glow 2.5s ease-in-out infinite;
  animation-delay: 0s, 1s; /* Start glow after scale completes */

  }
  .loader{
    transition: opacity 2s;
    animation: disappear 1ms 1s forwards;
  }
  @keyframes disappear {
    to {
      opacity: 0;
    }
  }

  @keyframes appear {
    to {
      opacity: 1;
    }
  }

  @keyframes scaleIn {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
  
  @keyframes glow {
    0% {
      filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0.5));
    }
    50% {
      filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.7));
    }
    100% {
      filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0.5));
    }
  }