@font-face {
    font-family: 'Protomolecule-regular';
    src: url('../../2025-Components/Fonts/Protomolecule-Regular.ttf') format('truetype');
}

.teams-section{
    background: #000000;
    scroll-behavior: smooth;
    background-size: 500px;
}

.teams-section-bg{
    height: 600px;
    z-index: 0;
    width: 700px;
    position: fixed;
    top: -100px;
    left: -170px;
    background: url('../../Images/landing2025_milky.png') no-repeat;
    filter: contrast(110%) brightness(100%) saturate(180%);
    background-size: 700px;
}

.dropdown-wrapper{
    position: fixed;
    color: white;
    top: 13vh;
    right: 10vw;
    z-index: 40;
    /* visibility: hidden; */
}

.dropdown{
    background: #ffffff23;
    padding:  7px 10px;
    color: white;
    text-align: center;
    border: 1px solid white;
    backdrop-filter: blur(3px);
    font-weight: 100;
    font-size: large;
    border-radius: 8px;
    box-shadow: 3px 5px 10px 0px #2e2b2b00;
    /* margin: 20px; */
}
.dropdown:focus {
    color: white; }

.dropdown option {
    background: white; 
    color: black; 
}

@media screen and (max-width: 800px) {
    .dropdown-wrapper{
    background: #ffffff23;
    visibility: hidden;
        position: fixed;
        color: white;
        width: 120px;
        top: 3vh;
        z-index: 21;
        left: 50%;
        margin-left: -60px;
    }
    
}


.up-container{
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #e62b1e;
    right: -50px;
    bottom: 40px;
    display: flex;
    justify-content: left;
    box-shadow: 3px 5px 10px 0px #2e2b2bc2;
}

.up_arrow{
    color: #EAE6E6;
    scale: 1.5;
    margin: auto;
    transform: transition all;
    transition-duration: 0.8;
}