.hero {
    height: 10 0vh;
    width: 100vw;
    background-color: black;
    z-index: 2;
    display: flex;
   font-family: "Inter", serif;
}

.cards {
    margin-right: 100px;
    z-index: 2;
}
.left {
    height: 100vh;
    width: 70vw;
    overflow: hidden;
    z-index: 2;
}

.right {
    margin-top: 4vh;
    margin-right: 9vw;
    padding-top: 1vw;
    height: 100vh;
    width: 30vw;
    z-index: 2;
}

.Question {
    opacity: 0;
    transform: translateY(-100px);
    width: 33vw;
    margin-top: 1vh;
    margin-bottom: 3vh;
    text-align: right;
    z-index: 2;
    font-size: 4vh;
    padding-left: 3%;

    color: white;
   font-family: "Inter", serif;
}

button {
    margin-top: 1vh;
    text-align: right;
    /* padding-left: 3%; */
    padding-right: 3%;
    margin-bottom: 3vh;
    font-size: 4vh;
    /* color: white; */
    z-index: 2;
}

.topBGif {
    width: 41vw;
    height: 30vh;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 17%,
        rgba(0, 0, 0, 0) 84%,
        rgba(0, 0, 0, 1) 100%
    );
    position: absolute;
}

/* .botBGif {
    width: 41vw;
    height: 20vh;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 20%
    );
    position: absolute;
} */

@media (max-aspect-ratio: 1.3/1) {
    button {
        text-align: right;
        padding-right: 3%;
        margin-bottom: 1vh;
        font-size: 3.5vh;
        color: white;
        z-index: 2;
    }

    .right {
        margin-top: 4vh;
        margin-right: -2vw;
        padding-top: 1vw;
        height: 100vh;
        width: 45vw;
        z-index: 2;
    }
}

@media (max-aspect-ratio: 1.76/1) {
    button {
        padding-top: 2vh;
        text-align: right;
        padding-right: 3%;
        margin-bottom: 1vh;
        font-size: 3.5vh;
        color: white;
        z-index: 2;
    }

    .right {
        margin-top: 4vh;
        margin-right: -2vw;
        padding-top: 1vw;
        height: 100vh;
        width: 45vw;
        z-index: 2;
    }

    .Answers {
        transform: translateY(10vh);
        margin: 10vh 4vw;
        margin-bottom: 8vh;
        width: 35vw;
        height: 40vh;
        line-height: 3.3vh;
    }

    .faq-answers {
        font-size: 2.5vh;
        font-weight: 500;
        line-height: 110%;
    }
}

/* .flower_spacebox {
    margin-left: 30%;
    height: 40%;
    width: 40%;
    z-index: 2;
} */

/* .yellow1 hr {
    position: absolute;
    transform: translate(3vw, 10vh);
    width: 0vw;
    height: 30vh;
    background-color: rgb(240, 240, 25);
    border-radius: 0 5vh 5vh 0;
    border-color: yellow;
    z-index: 1;
} */

/* .yellow2 hr {
    position: absolute;
    transform: translate(39vw, 10vh);
    width: 00vw;
    height: 30vh;
    background-color: rgb(240, 240, 25);
    border-radius: 5vh 5vh 5vh 5vh;
    border-color: yellow;
    z-index: 1;
} */

/* .whiteflower {
    
    opacity: 0;
    position: absolute;
    width: 1vh;
    z-index: 100;
    transform: translate(15vw, 35vh);
} */

/* .redflower {
    opacity: 0;
    
    width: 5vh;
    position: absolute;
    z-index: 100000;
        transform: translate(42vw, -1vh);
   
} */

.gif {
    width: 41vw;
    height: 30vh;
}

.Answers {
    opacity: 0;
    transform: translate(10vw, 0vh);
    position: relative;
    /* margin: 2vh 0vw; */
    margin: 0vh 0vw 10vh 0vw;
    margin-bottom: 10vh;
    z-index: 2;
    /* display: grid; */
    /* align-items: center; */
    text-align: left;
    font-size: 3vh;
    font-weight: 200;
    /* text-align: right; */
    width: 41vw;
    color: white;
    height: auto;
    line-height: 3.3vh;
}

.faq {
    margin-top: 1vh;
    opacity: 0;
    z-index: 2;
    height: 5vh;
    position: absolute;
    /* height: 20vh; */
    bottom: 10vh;
}

.faq-answers {
    font-weight: 500;
    line-height: 120%;
}

.samarth {
    position: relative;
    z-index: 1;
}
