@font-face {
    font-family: 'Protomolecule-regular';
    src: url('../Fonts/Protomolecule-Regular.ttf') format('truetype');
}
.ABOUT_2025_main{
    width: screen;
    padding-top: 40vh;
    min-height: 300vh;
    background-color: black;
    z-index: 10;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 80px;
    padding-right: 100px;
    padding-left: 100px;
    
    text-transform: uppercase;
}

.ABOUT_2025_main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black; /* Or any desired color */
    opacity: 0.8; /* Adjust opacity for dimming */
    z-index: 0; /* Ensure it stays behind content */
    pointer-events: none; /* Allow interaction with child elements */
}

.about_section_1, .about_section_3 {
    width: 100%;
    display:  flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 100px;
    height: 100vh;
    
}

.about_section_2{
    width: 100%;
    display:  flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100vh;   
}



@media screen and (max-width: 1080px) {
    .ABOUT_2025_main{
        background: url('../../Images/stars1.jpg')  top center; 
        background-size: 100%;
        
        /* padding: 60px 10px; */
        text-transform: uppercase;
        align-items: center;
    }

    .about_section_1, .about_section_3 {
        width: 100vw;
        display:  flex;
        justify-content: center;
        gap: 80px;
        height: 100vh;
        align-items: center;
        z-index: 5; 
        padding: 0 20px;
        margin-bottom: -120px;
    }
    
    .about_section_2{
        width: 100vh;
        display:  flex;
        justify-content: center;
        height: 100vh;   
        padding: 0 20px;
        align-items: center;
        margin-bottom: -120px;
        z-index: 5; 
        gap: 80px;
    }
    
    
}


@media screen and (max-width: 800px) {
    .ABOUT_2025_main{
        background: url('../../Images/stars1.jpg')  top center; 
        background-size: 100%;
        
        /* padding: 60px 10px; */
        text-transform: uppercase;
        align-items: center;
    }

    .about_section_1, .about_section_3 {
        width: 100vw;
        display:  flex;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 80px;
        height: 100vh;
        align-items: center;
        z-index: 5; 
        padding: 0 20px;
        margin-bottom: -120px;
    }
    
    .about_section_2{
        width: 100vh;
        display:  flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;   
        padding: 0 20px;
        align-items: center;
        margin-bottom: -120px;
        z-index: 5; 
        gap: 80px;
    }
    
    
}


@media screen and (max-width: 480px) {
    .ABOUT_2025_main{
        width: 100vw;
        min-height: 300vh;
        background-color: black;
        z-index: 1;
        padding-top: 40vh;
        position: relative;
        display: flex;
        flex-direction: column;
        background: url('../../Images/stars1.jpg')  top center; 
        background-size: 100%;
        row-gap: 80px;
        /* padding: 60px 10px; */
        text-transform: uppercase;
        align-items: center;
    }

    .about_section_1, .about_section_3 {
        width: 100vw;
        display:  flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        gap: 80px;
        height: 100vh;
        align-items: center;
        z-index: 5; 
        padding: 0 20px;
        margin-bottom: -120px;
    }
    
    .about_section_2{
        width: 100vh;
        display:  flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;   
        padding: 0 20px;
        align-items: center;
        margin-bottom: -120px;
        z-index: 5; 
        gap: 80px;
    }
    
    
}